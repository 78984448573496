<template>
  <ModernModalLayout
    :title="title"
    :close-label="no"
    :dense-content="!description && !$scopedSlots.description"
  >
    <div>
      <slot name="description">{{ description }}</slot>
    </div>

    <template #actions:append>
      <v-btn
        class="px-10"
        depressed
        :loading="$wait('action')"
        color="primary"
        @click="submit"
      >
        {{ yes }}
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import waitable from '@/utils/mixins/waitable';

export default {
  components: { ModernModalLayout },
  mixins: [waitable],

  props: {
    title: { type: String, default: null },
    description: { type: String, default: null },
    no: { type: String, default: 'Отмена' },
    yes: { type: String, default: 'Подтвердить' },
    messages: {
      type: Object,
      default: () => ({
        successfulAction: 'Успешно',
        errorAction: 'Произошла ошибка',
      }),
    },
    onSubmit: { type: Function, required: true },
  },

  mounted() {
    document.addEventListener('keyup', this.onEnter);
  },

  beforeDestroy() {
    document.removeEventListener('keyup', this.onEnter);
  },

  methods: {
    onEnter(e) {
      e.keyCode === 13 && this.submit();
    },
    async submit() {
      const result = this.onSubmit();

      if (result instanceof Promise)
        await this.$loadingNotify(
          result,
          'action',
          this.messages.errorAction,
          this.messages.successfulAction,
        )
          .catch(() => {})
          .finally(() => this.$emit('close'));
      else {
        this.$emit('close');
      }
    },
  },
};
</script>
